import React from 'react';
import get from "lodash/get";
import set from "lodash/set";
import isString from "lodash/isString";
import merge from "lodash/merge";

import i18n from "./i18n";

export const PIC_CONFIG = {
  key: 'pic',
  collection: 'initiatives',
  logo: '/assets/img/logo_platic.png',
  favicon: '/assets/img/favicon_platic.png',
  welcome: i18n.t('Bienvenido a platIC.'),
  helpModal: {
    title: i18n.t('Plataforma de Iniciativas Ciudadanas 🙌'),
    subtitle: i18n.t('¿QUÉ INICIATIVAS?'),
    description: [
      i18n.t('El objetivo de este proyecto')
    ],
    image: '/assets/img/platic.gif',
    type: 'help',
    id: '',
    options: '',
    textBeforeIcons: i18n.t('Proyecto financiado')
  },
  filters: [i18n.t('Accesibilidad'), i18n.t('Arte urbano'), i18n.t('Arquitectura'), i18n.t('Autogestión'), i18n.t('Cuidado'), i18n.t('Culto'), i18n.t('Cultura'), i18n.t('Deporte'), i18n.t('Derechos sociales'), i18n.t('Diversidad'), i18n.t('Educación'), i18n.t('Gentrificación / Turistificación'), i18n.t('Integración'), i18n.t('Igualdad'), i18n.t('Mediación'), i18n.t('Medio ambiente'), i18n.t('Movilidad sostenible'), i18n.t('Participación ciudadana'), i18n.t('Patrimonio material'), i18n.t('Patrimonio cultural inmaterial'), i18n.t('Personas mayores'), i18n.t('Política social'), i18n.t('Urbanismo'), i18n.t('Salud')],
  purpose: [
    {value: i18n.t('Accesibilidad'), label: i18n.t('Accesibilidad: eliminación de barreras, diseño universal...')},
    {value: i18n.t('Arte urbano'), label: i18n.t('Arte urbano: educación artística, graffiti...')},
    {value: i18n.t('Arquitectura'), label: i18n.t('Arquitectura: patrimonio industrial, bien de interés cultural...')},
    {value: i18n.t('Autogestión'), label: i18n.t('Autogestión: gobernanza urbana, trabajo colaborativo en red...')},
    {value: i18n.t('Cuidado'), label: i18n.t('Cuidado: salud, alimentación, calidad de vida...')},
    {value: i18n.t('Culto'), label: i18n.t('Culto: religión, creencias, rito...')},
    {value: i18n.t('Cultura'), label: i18n.t('Cultura: educación libre, aprendizaje colaborativo...')},
    {value: i18n.t('Deporte'), label: i18n.t('Deporte: actividad física y/o lúdica, gamificación...')},
    {
      value: i18n.t('Derechos sociales'),
      label: i18n.t('Derechos sociales: derechos Humanos, igualdad de derechos y deberes...')
    },
    {value: i18n.t('Diversidad'), label: i18n.t('Diversidad: apoyo a la integración, atención a la diversidad...')},
    {
      value: i18n.t('Educación'),
      label: i18n.t('Educación: aprendizaje basado en proyectos, comunidades de aprendizaje, AMPA...')
    },
    {value: i18n.t('Gentrificación / Turistificación'), label: i18n.t('Gentrificación / Turistificación: alquiler vacacional, alojamientos turísticos, oferta cultural y de ocio, terrazas, derecho a la ciudad, ruido...')},
    {value: i18n.t('Integración'), label: i18n.t('Integración: interculturalidad, inmersión cultural...')},
    {value: i18n.t('Igualdad'), label: i18n.t('Igualdad: perspectiva de género, feminismo...')},
    {value: i18n.t('Mediación'), label: i18n.t('Mediación: intermediación, facilitación...')},
    {value: i18n.t('Medio ambiente'), label: i18n.t('Medio ambiente: ecología, permacultura, huertos urbanos...')},
    {
      value: i18n.t('Movilidad sostenible'),
      label: i18n.t('Movilidad sostenible: transporte público, espacios tranquilos y saludables...')
    },
    {
      value: i18n.t('Participación ciudadana'),
      label: i18n.t('Participación ciudadana: trabajo colaborativo, herramientas para la organización ciudadana, empoderamiento vecinal...')
    },
    {value: i18n.t('Patrimonio material'), label: 'Patrimonio material: arqueología, preexistencias, vestigios...'},
    {
      value: i18n.t('Patrimonio cultural inmaterial'),
      label: i18n.t('Patrimonio cultural inmaterial:  tradiciones, costumbres, oficios...')
    },
    {value: i18n.t('Personas mayores'), label: 'Personas mayores: atención a la tercera edad, envejecimiento activo...'},
    {
      value: i18n.t('Política social'),
      label: i18n.t('Política social: banco de tiempo, crowfunding (economía colaborativa), cooperativas vecinales...')
    },
    {value: i18n.t('Urbanismo'), label: i18n.t('Urbanismo: regeneración urbana, rutas urbanas, estrategias urbanas...')},
    {value: i18n.t('Salud'), label: i18n.t('Salud: bienestar, vida saludable...')}
  ],
  area: [
    {
      value: i18n.t('Espacios culturales'),
      label: i18n.t('Espacios culturales: Centro cultural, bibliotecas, museos, universidad...')
    },
    {
      value: i18n.t('Sedes ciudadanas'),
      label: i18n.t('Sedes ciudadanas: Sede de asociaciones, coworking(espacios de trabajo colaborativo), talleres...')
    },
    {value: i18n.t('Espacio público'), label: i18n.t('Espacio público: Plazas, parques, calles, vacíos urbanos...')},
    {value: i18n.t('Espacios virtuales'), label: i18n.t('Espacios virtuales: Redes sociales, plataformas, blogs...')}
  ],
  relatedInitiatives: true,
  downloadCSV: true,
  searchLabel: i18n.t('Buscar por colectivo'),
  formNameLabel: i18n.t('Nombre del colectivo'),
  styles: {
    navbar: '#Ff8326',
    navbarUnderline: 'linear-gradient(to top, #d75d00 2px, rgba(0, 174, 199, 0) 2px), linear-gradient(to top, #ffbb88 2px, rgba(210, 210, 210, 0) 1px)',
    button: '#FF994F',
    buttonDarker: '#FF8226',
    buttonUnderline: 'linear-gradient(to top, #d75d00 2px, rgba(0, 174, 199, 0) 2px), linear-gradient(to top, #ffbb88 2px, rgba(210, 210, 210, 0) 1px)',
    circle: '#d75d00',
    lineGradient: [
      'interpolate',
      ['linear'],
      ['line-progress'],
      0, '#d75d00',
      0.1, '#ea6400',
      0.3, '#ff8326',
      0.5, '#ff9b51',
      0.7, '#ffa867',
      1, '#ffbb88'
    ]
  },
  ods: [
    {value: 1, label: i18n.t("Objetivo 1 - Fin de la pobreza"), slug: 'poverty'},
    {value: 2, label: i18n.t("Objetivo 2 - Hambre cero"), slug: 'hunger'},
    {value: 3, label: i18n.t("Objetivo 3 - Salud y bienestar"), slug: 'health'},
    {value: 4, label: i18n.t("Objetivo 4 - Educación de calidad"), slug: 'education'},
    {value: 5, label: i18n.t("Objetivo 5 - Igualdad de género"), slug: 'gender-equality'},
    {value: 6, label: i18n.t("Objetivo 6 - Agua limpia y saneamiento"), slug: 'water-and-sanitation'},
    {value: 7, label: i18n.t("Objetivo 7 - Energía asequible y no contaminante"), slug: 'energy'},
    {value: 8, label: i18n.t("Objetivo 8 - Trabajo decente y crecimiento económico"), slug: 'economic-growth'},
    {value: 9, label: i18n.t("Objetivo 9 - Industria, innovación e infraestructura"), slug: 'infrastructure'},
    {value: 10, label: i18n.t("Objetivo 10 - Reducción de las desigualdades"), slug: 'inequality'},
    {value: 11, label: i18n.t("Objetivo 11 - Ciudades y comunidades sostenibles"), slug: 'cities'},
    {
      value: 12,
      label: i18n.t("Objetivo 12 - Producción y consumos responsables"),
      slug: 'sustainable-consumption-production'
    },
    {value: 13, label: i18n.t("Objetivo 13 - Acción por el clima"), slug: 'climate-change-2'},
    {value: 14, label: i18n.t("Objetivo 14 - Vida submarina"), slug: 'oceans'},
    {value: 15, label: i18n.t("Objetivo 15 - Vida de ecosistemas terrestres"), slug: 'biodiversity'},
    {value: 16, label: i18n.t("Objetivo 16 - Paz, justicia e instituciones sólidas"), slug: 'peace-justice'},
    {value: 17, label: i18n.t("Objetivo 17 - Alianzas para lograr los objetivos"), slug: 'globalpartnerships'},
  ]
};

export const COVID_CONFIG = {
  key: 'covid',
  name: i18n.t('Málaga Solidaria'),
  collection: 'covid',
  logo: '/assets/img/logo_covid.png',
  favicon: '/assets/img/favicon_covid.png',
  welcome: i18n.t('Bienvenido a Málaga Solidaria.'),
  helpModal: {
    title: i18n.t('Málaga Solidaria - Red de ayudas COVID-19'),
    subtitle: i18n.t('¿Qué iniciativas solidarias hay en tu ciudad en tiempos de COVID-19? ¿Organizas alguna de ellas? ¿Tienes alguna necesidad?'),
    description: [
      i18n.t('El objetivo de esta plataforma es mostrar qué iniciativas solidarias podemos encontrar en la ciudad en la situación actual que vivimos, para darlas a conocer a los ciudadanos y así crear una red de ayudas en tiempos de COVID-19.'),
      i18n.t('Si eres un colectivo o una persona individual que está llevando a cabo alguna acción solidaria, puedes registrar tu iniciativa en la plataforma, introduciendo tu contacto para que el resto de ciudadanos pueda conocerte.'),
      i18n.t('Si tienes alguna necesidad puedes buscar por temáticas las diferentes acciones solidarias que se están llevando a cabo.')
    ],
    image: '/assets/img/covid.gif',
    type: 'help',
    id: '',
    options: '',
    textBeforeIcons: i18n.t('Málaga Solidaria se ha desarrollado en el marco de la investigación del proyecto platIC (Plataforma de Iniciativas Ciudadanas).')
  },
  filters: [i18n.t('Aportación económica'), i18n.t('Deporte desde casa'), i18n.t('Donación de alimentos'), i18n.t('Donación de material de protección'), i18n.t('Farmacias (reparto a domicilio)'), i18n.t('Hago mascarillas'), i18n.t('Peluquería a domicilio (1ª necesidad)'), i18n.t('Pequeño comercio local (reparto a domicilio)'), i18n.t('Recados'), i18n.t('Te llamo por teléfono'), i18n.t('Otros')],
  purpose: [
    {value: i18n.t('Aportación económica'), label: i18n.t('Aportación económica')},
    {value: i18n.t('Deporte desde casa'), label: i18n.t('Deporte desde casa')},
    {value: i18n.t('Donación de alimentos'), label: i18n.t('Donación de alimentos')},
    {value: i18n.t('Donación de material de protección'), label: i18n.t('Donación de material de protección')},
    {value: i18n.t('Farmacias (reparto a domicilio)'), label: i18n.t('Farmacias (reparto a domicilio)')},
    {value: i18n.t('Hago mascarillas'), label: i18n.t('Hago mascarillas')},
    {value: i18n.t('Peluquería a domicilio (1ª necesidad)'), label: i18n.t('Peluquería a domicilio (1ª necesidad)')},
    {
      value: i18n.t('Pequeño comercio local (reparto a domicilio)'),
      label: i18n.t('Pequeño comercio local (reparto a domicilio)')
    },
    {value: i18n.t('Recados'), label: i18n.t('Recados')},
    {value: i18n.t('Te llamo por teléfono'), label: i18n.t('Te llamo por teléfono')},
    {value: i18n.t('Otros'), label: i18n.t('Otros')}
  ],
  area: null,
  relatedInitiatives: false,
  downloadCSV: false,
  searchLabel: i18n.t('Buscar por iniciativa'),
  formNameLabel: i18n.t('Nombre de la iniciativa - Organizador'),
  styles: {
    navbar: '#E73778',
    navbarUnderline: 'linear-gradient(to top, #E73778 2px, rgba(0, 174, 199, 0) 2px), linear-gradient(to top, #fdb0cc 2px, rgba(210, 210, 210, 0) 1px)',
    button: '#9CCFD4',
    buttonDarker: '#73b8bf',
    buttonUnderline: 'linear-gradient(to top, #73b8bf 2px, rgba(0, 174, 199, 0) 2px), linear-gradient(to top, #b8e4e8 2px, rgba(210, 210, 210, 0) 1px)',
    circle: '#2E3146',
    lineGradient: '#2E3146'
  }
};

const i18nConfigPathList = [
  'name',
  'welcome',
  'helpModal.title',
  'helpModal.subtitle',
  'searchLabel',
  'helpModal.textBeforeIcons',
  'formNameLabel',
  'helpModal.description',
  'filters[]',
  'purpose[].label',
  'area[].label',
  'ods[].label',
];
export const translateConfig = (config = {}, t) => {
  if (!t) return config;
  let counter = 0;
  const tConfig = {};
  const queue = i18nConfigPathList;
  while (queue.length && counter < 500000) {
    counter++

    const [path, ...restPath] = queue.shift().split('[]');

    if (restPath.length) {
      set(tConfig, path, []);
      Object.keys(get(config, path, [])).forEach((key) => {
        const nextPath = `${path}[${key}]${restPath.join('[]')}`; //[...currentPath, key, ...path.slice(position + 1)];
        queue.push(nextPath);
      });
      continue;
    }

    const value = get(config, path, null);
    if (value == null) continue; // error case

    if (isString(value)) {
      set(tConfig, path, t(value));
    }
  }

  return merge(config, tConfig);
};
