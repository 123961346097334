import React from "react";
import { PIC_CONFIG } from './config';
import { parseListFromAPI } from "./utils";
import {withTranslation} from "react-i18next";

const style = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '.5rem .5rem 0'
  },
  image: {
    width: '120px',
  },
  title: {
    width: '100%',
    marginBottom: '.5rem',
  }
};

const SIZE = 300; // 75 | 150 | 300
const ODS = PIC_CONFIG.ods.map(ods => {
  // return {...ods, href: `/assets/img/ods/ods-${ods.value}.jpg`} // full size and uncompressed
  return {...ods, href: `/assets/img/ods/ods-${ods.value}-${SIZE}x${SIZE}.gif`}
})

const ODSTags = ({ ods: odsIdListStr, t }) => {
  const odsIds = parseListFromAPI(odsIdListStr);

  return <div style={style.container}>
    <h6 className="text-muted" style={style.title}>{t('Objetivos de Desarrollo Sostenible')}:</h6>
    {ODS.filter((({value}) => odsIds.includes(value))).map((entry, index) => {
      return <img key={index} style={style.image} src={entry.href} alt={t(entry.label)} title={t(entry.label)}/>
    })}
  </div>
};

export default withTranslation()(ODSTags);
