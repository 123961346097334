import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import firebase from 'firebase/app';
import { storage } from './App';
import Select from 'react-select';
import Loader from 'react-loader-spinner';
import Radium from 'radium';
import {withTranslation} from "react-i18next";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      email: '',
      password: '',
      name: '',
      web: '',
      phone: '',
      address: '',
      purpose: [],
      action: '',
      area: [],
      ods: [],
      enabler: '',
      description: '',
      demands: '',
      image: '',
      creator: this.props.email,
      twitter: '',
      facebook: '',
      youtube: '',
      group: '',
      file: null,
      initiatives: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleMulti = this.handleMulti.bind(this);
    this.handleSubmission = this.handleSubmission.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }
  handleUpload = e => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      this.setState(() => ({ file }));

      const filename = Date.now() + '_' + file.name;

      const uploadTask = storage.ref(`images/${filename}`).put(file);
      uploadTask.on('state_changed',
        (snapshot) => {
          // progrss function ....
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          this.setState({ progress });
        },
        (error) => {
          // error function ....
          console.log(error);
        },
        () => {
          // complete function ....
          storage.ref('images').child(filename).getDownloadURL().then(image => {
            this.setState({ image });
            // console.log(image, this.state)
          })
        });
    }
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  handleMulti(fieldName) {
    return (selectedOption) => {
      this.setState({[fieldName]: selectedOption});
    }
  }

  handleSubmission(event) {
    event.preventDefault();
    let data = this.props.data;
    data.geometry.type = 'Point';
    data.properties = {
      id: this.state.id === '' ? Math.max(...this.props.points.map(item => item.properties.id), 0) + Math.floor(Math.random() * 10000) + 1 : this.state.id,
      name: this.state.name,
      url: this.state.web === '' ? this.state.web : (this.state.web.startsWith('http') ? this.state.web : 'https://' + this.state.web),
      address: this.state.address,
      purpose: this.state.purpose === '' ? [] : this.state.purpose.map(item => item.value),
      action: this.state.action,
      area: this.state.area === '' ? [] : this.state.area.map(item => item.value),
      ods: this.state.ods === '' ? [] : this.state.ods.map(item => item.value),
      enabler: this.state.enabler,
      description: this.state.description.replace(/\r?\n/g, '<br/>'),
      demands: this.state.demands.replace(/\r?\n/g, '<br/>'),
      image: this.state.image,
      mail: this.state.email,
      creator: this.state.creator,
      group: this.state.group,
      twitter: this.state.twitter === '' ? this.state.twitter : (this.state.twitter.startsWith('http') ? this.state.twitter : 'https://' + this.state.twitter),
      facebook: this.state.facebook === '' ? this.state.facebook : (this.state.facebook.startsWith('http') ? this.state.facebook : 'https://' + this.state.facebook),
      youtube: this.state.youtube === '' ? this.state.youtube : (this.state.youtube.startsWith('http') ? this.state.youtube : 'https://' + this.state.youtube),
      phone: this.state.phone,
      related: this.state.initiatives === '' ? '' : this.state.initiatives.map(item => item.value)
    }
    firebase.firestore().collection(this.props.config.collection).doc(String(data.properties.id)).set(data)
      .then(() => {
        this.props.handler(false, `${this.props.t('Iniciativa añadida con éxito')}. ${this.props.t('¡Gracias por colaborar!')}`, this.props.data.id);
      })
      .catch((error) => {
        NotificationManager.error(this.props.t('Ha ocurrido un error al crear la iniciativa.'));
      });
    event.preventDefault();
  }

  componentDidMount() {
    const allNames = this.props.points.map(item => {
      return { value: item.properties.id, label: item.properties.name }
    });

    if (this.props.data.properties.featureLocation !== undefined) {
      if (this.props.data.properties.featureLocation.length > 0) {
        this.setState({
          id: this.props.data.properties.featureProperties.id === undefined ? '' : this.props.data.properties.featureProperties.id,
          email: this.props.data.properties.featureProperties.mail === undefined ? '' : this.props.data.properties.featureProperties.mail,
          address: this.props.data.properties.featureProperties.address === undefined ? '' : this.props.data.properties.featureProperties.address,
          name: this.props.data.properties.featureProperties.name === undefined ? '' : this.props.data.properties.featureProperties.name,
          web: this.props.data.properties.featureProperties.url === undefined ? '' : this.props.data.properties.featureProperties.url,
          phone: this.props.data.properties.featureProperties.phone === undefined ? '' : this.props.data.properties.featureProperties.phone,
          purpose: this.props.data.properties.featureProperties.purpose === '' ? this.props.data.properties.featureProperties.purpose : [].concat.apply([], JSON.parse(this.props.data.properties.featureProperties.purpose).map(el => this.props.config.purpose.filter(x => x.value === el))),
          action: '',
          area: this.props.config.area
            ? this.props.data.properties.featureProperties.area === '' ? this.props.data.properties.featureProperties.area : [].concat.apply([], JSON.parse(this.props.data.properties.featureProperties.area).map(el => this.props.config.area.filter(x => x.value === el)))
            : [],
          ods: this.props.config.ods && this.props.data.properties.featureProperties.ods
            ? [].concat.apply([], JSON.parse(this.props.data.properties.featureProperties.ods)
                .map(el => this.props.config.ods.filter(x => x.value === el)))
            : [],
          enabler: '',
          description: this.props.data.properties.featureProperties.description === undefined ? '' : this.props.data.properties.featureProperties.description,
          demands: this.props.data.properties.featureProperties.demands === undefined ? '' : this.props.data.properties.featureProperties.demands,
          image: this.props.data.properties.featureProperties.image === undefined ? '' : this.props.data.properties.featureProperties.image,
          creator: this.props.data.properties.featureProperties.creator === undefined ? '' : this.props.data.properties.featureProperties.creator,
          twitter: this.props.data.properties.featureProperties.twitter === undefined ? '' : this.props.data.properties.featureProperties.twitter,
          facebook: this.props.data.properties.featureProperties.facebook === undefined ? '' : this.props.data.properties.featureProperties.facebook,
          youtube: this.props.data.properties.featureProperties.youtube === undefined ? '' : this.props.data.properties.featureProperties.youtube,
          group: '',
          initiatives: this.props.data.properties.featureProperties.related === '' ? this.props.data.properties.featureProperties.related : [].concat.apply([], JSON.parse(this.props.data.properties.featureProperties.related).map(el => allNames.filter(x => x.value === el))),
          file: null
        })
      }
    }
  }

  render() {
    const styles = {
      button: {
        backgroundColor: this.props.styles.button,
        borderColor: this.props.styles.buttonDarker,
        ':hover': {
          backgroundColor: this.props.styles.buttonDarker,
        }
      },
      input: {
        backgroundImage: this.props.styles.buttonUnderline
      }
    };
    const pnamess = this.props.points.map(item => {
      return { value: item.properties.id, label: item.properties.name }
    });

    let imageOk = this.state.image.length > 0 ? <span>{this.props.t('¡Imagen subida con éxito!')}</span> : (this.state.progress < 100 ? <Loader
      type="Oval"
      color={this.props.styles.button}
      height="1rem"
      width="1rem"
    /> : null);
    return (
      <form className='form' onSubmit={this.handleSubmission}>
        <div className='form-row'>
          <div className='form-group col-md-12'>
            <label htmlFor='name'>{this.props.config.formNameLabel}</label>
            <input
              type='text'
              className='form-control'
              style={styles.input}
              id='name'
              key='name'
              placeholder={this.props.t('Nombre de la Iniciativa')}
              required
              value={this.state.name}
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group col-md-6'>
            <label htmlFor='web'>{this.props.t('Web')}</label>
            <input
              type='text'
              className='form-control'
              style={styles.input}
              id='web'
              key='web'
              placeholder='https://example.com'
              value={this.state.web}
              onChange={this.handleChange}
            />
          </div>

          <div className='form-group col-md-6'>
            <label htmlFor='phone'>{this.props.t('Teléfono')}</label>
            <input
              type='text'
              className='form-control'
              style={styles.input}
              id='phone'
              key='phone'
              placeholder='+34 629118190'
              value={this.state.phone}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group col-md-6'>
            <label htmlFor='address'>{this.props.t('Dirección')}</label>
            <input
              type='text'
              className='form-control'
              style={styles.input}
              id='address'
              key='address'
              placeholder={this.props.t('Calle de la Piruleta 1, Bloque 2, Puerta 4')}
              value={this.state.address}
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group col-md-6'>
            <label htmlFor='email'>{this.props.t('Email')}</label>
            <input
              type='text'
              className='form-control'
              style={styles.input}
              id='email'
              key='email'
              placeholder='tec@gmail.es'
              value={this.state.email}
              onChange={this.handleChange}
              />
          </div>
        </div>
        <div className='form-row' style={{ position: 'relative' }}>
          <div className='form-group col-md-12'>
            <label htmlFor='purpose'>{this.props.t('Temática')}</label>
            <Select
              inputId="purpose"
              value={this.state.purpose}
              onChange={this.handleMulti('purpose')}
              isMulti={true}
              isSearchable={true}
              options={this.props.config.purpose}
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0, position: 'absolute', bottom: 0 }}
              value={this.state.purpose}
              required
            />
          </div>
        </div>
        {this.props.config.area && (
          <div className='form-row'>
            <div className='form-group col-md-12'>
              <label htmlFor='area'>{this.props.t('Ámbito')}</label>
              <Select
                inputId="area"
                value={this.state.area}
                onChange={this.handleMulti('area')}
                isMulti={true}
                isSearchable={true}
                options={this.props.config.area}
              />
            </div>
          </div>
        )}
        {this.props.config.ods && (
          <div className='form-row'>
            <div className='form-group col-md-12'>
              <label htmlFor='ods'>{this.props.t('Objetivos de Desarrollo Sostenible')}</label>
              <Select
                inputId="ods"
                value={this.state.ods}
                onChange={this.handleMulti('ods')}
                isMulti={true}
                isSearchable={true}
                options={this.props.config.ods}
              />
            </div>
          </div>
        )}
        {this.props.config.relatedInitiatives && (
          <div className='form-row'>
            <div className='form-group col-md-12'>
              <label htmlFor='initiatives'>{this.props.t('Iniciativas relacionadas')}</label>
              <Select
                inputId="initiatives"
                value={this.state.initiatives}
                onChange={this.handleMulti('initiatives')}
                isMulti={true}
                isSearchable={true}
                options={pnamess}
              />
            </div>
          </div>
        )}
        <div className='form-row'>
          <div className='form-group col-md-12'>
            <label htmlFor='description'>{this.props.t('Descripción')}</label>
            <textarea
              className='form-control'
              style={styles.input}
              placeholder={this.props.t('Describe aquí la iniciativa')}
              id='description'
              key='description'
              rows='7'
              value={this.state.description}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group col-md-12'>
            <label htmlFor='demands'>{this.props.t('Demandas')}</label>
            <textarea
              className='form-control'
              style={styles.input}
              placeholder={this.props.t('Describe aquí las demandas')}
              id='demands'
              key='demands'
              rows='7'
              value={this.state.demands}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group col-md-12'>
            <div className='form-row'>
              <label htmlFor='twitter' style={{ marginBottom: '5px' }}>Twitter</label>
              <input
                type='text'
                className='form-control'
                style={styles.input}
                id='twitter'
                key='twitter'
                placeholder='https://twitter.com/devtwitter'
                value={this.state.twitter}
                onChange={this.handleChange}
              />
            </div>
            <div className='form-row'>
              <label htmlFor='youtube' style={{ marginBottom: '5px' }}>Youtube</label>
              <input
                type='text'
                className='form-control'
                style={styles.input}
                id='youtube'
                key='youtube'
                placeholder='https://youtube.com'
                value={this.state.youtube}
                onChange={this.handleChange}
              />
            </div>
            <div className='form-row'>
              <label htmlFor='facebook' style={{ marginTop: '5px', marginBottom: '3px' }}>Facebook</label>
              <input
                type='text'
                className='form-control'
                style={styles.input}
                id='facebook'
                key='facebook'
                placeholder='https://facebook.com/devfacebook/'
                value={this.state.facebook}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div>
          </div>
          <label
            htmlFor="file"
            key="file"
            className="btn btn-primary-filters btn-sm"
            style={styles.button}
          >
            {this.props.t('Seleccionar imagen')}
          </label>
          <input
            type="file"
            id="file"
            style={{ visibility: 'hidden' }}
            accept=".png,.jpg"
            onChange={this.handleUpload}
          />
          {imageOk}
        </div>
        <div className='modal-footer justify-content-center'>
          <input
            className='btn btn-primary-filters btn-sm'
            style={styles.button}
            key='submit'
            type='submit'
            value={this.props.t('Enviar')}
            disabled={this.state.progress < 100}
          />
        </div>
      </form>
    );
  }
}

export default withTranslation()(Radium(Form));
