import { withTranslation } from "react-i18next";
import { translateConfig } from "../config";
import React, { useEffect } from "react";

const withCustomTranslation = (Component) => {
  return withTranslation()((props) => {
    const config = translateConfig(props.config, props.t);
    useEffect(() => {
      props.i18n.on('languageChanged', () => window.location.reload());
    }, []);
    return <Component {...props} config={config}/>;
  });
};

export default withCustomTranslation;
