export const parseListFromAPI = (sourceStr) => {
  if (!sourceStr) return [];

  try {
    return JSON.parse(sourceStr);
  } catch (ex) {
    console.error(ex);
  }

  return []
}


/**
 * @param cluster
 * @param clusterSource
 * @returns {Promise<Object<string, string[]>>}
 */
const getFeatureIdsBySingleClusterId = (cluster, clusterSource) => new Promise((resolve) => {
  try {
    clusterSource.getClusterLeaves(cluster.id, cluster.properties.point_count, 0, (err, features = []) => {
      resolve(err ? null : {
        clusterId: cluster.id,
        features: features.map(({ properties: { id } }) => id)
      });
    })
  } catch (e) {
    resolve(null);
  }
});

/**
 * @param allClusters
 * @param clusterSource
 * @returns {Promise<Object<string, string[]>>}
 */
export const getFeaturesByClusterId = (allClusters = [], clusterSource) => new Promise(async (resolve) => {
  const result = await Promise.all(allClusters.map((cluster) => getFeatureIdsBySingleClusterId(cluster, clusterSource)));

  const featuresByClusterId = {};
  for (const obj of result) {
    const { clusterId, features = [] } = obj || {};

    if (clusterId != null && features.length) {
      featuresByClusterId[clusterId] = features;
    }
  }

  resolve(featuresByClusterId);
})
