import React, { Component } from 'react';
import Radium from 'radium';
import {withTranslation} from "react-i18next";

class Help extends Component {

  render() {
    const styles = {
      button: {
        backgroundColor: this.props.styles.button,
        borderColor: this.props.styles.buttonDarker,
        ':hover': {
          backgroundColor: this.props.styles.buttonDarker,
        }
      }
    };
    const modalHeader = <div className='modal-header'> <h5 className='modal-title'>{this.props.title}</h5><button type='button' className='close' aria-label='Close' onClick={() => this.props.handler(false)}><span aria-hidden='true'>&times;</span></button></div>;

    return (
      <div className='modal fade show' style={{ display: 'block', overflow: 'auto' }} tabIndex='-1' role='dialog' aria-labelledby='ModalLabel' aria-hidden='true'>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            {modalHeader}

            <div className='modal-body'>
              <div className='backtittle'><h6 style={{padding:'5px'}}>{this.props.subtitle}</h6></div>
              {this.props.description.map((paragraph, index) =>
                <p key={index} className='text-justify'>{this.props.t(paragraph)}</p>
              )}

              <div className='row'>
                  <div className='col-md-12 col-lg-12 col-sm-12 text-center'>

                    <button
                      type='button'
                      className='btn btn-primary-filters btn-sm'
                      style={styles.button}
                      onClick={() => this.props.handler(false)}
                    >
                      {this.props.t('¡Comenzar!')}
                    </button>

                  </div>
                </div>

              <img className='portada' src={this.props.image} alt={this.props.t('portada')} />

              {this.props.textBeforeIcons && (
                <p className='text-before-icons text-justify'>
                  {this.props.textBeforeIcons}
                </p>
              )}

              <div className='container'>

                <div className='row'>
                  <div className='col-md-12 col-lg-12 col-sm-12 text-center'>
                    <p className='text'>{`${this.props.t('Contáctanos')}:`}</p>

                  <a href='mailto:info@teciudadania.uma.es' style={{paddingRight:'20px'}}><img className='' src='/assets/img/mail.png' alt='mail'></img></a>
                  <a href='https://twitter.com/teciudadania?lang=es' target='_blank' rel='noopener noreferrer' style={{paddingRight:'20px'}}><img className='' src='/assets/img/twitter.png' alt='twitter'></img></a>
                  <a href='https://www.facebook.com/teciudadania.ciudadania.1' target='_blank' rel='noopener noreferrer' style={{paddingRight:'20px'}}><img className='' src='/assets/img/facebook.png' alt='facebook'></img></a>
                  <a href='https://www.instagram.com/teciudadania/?hl=es' target='_blank' rel='noopener noreferrer'><img className='' src='/assets/img/instagram.png' alt='instagram'></img></a>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 col-lg-12 col-sm-12 text-center'>

                  <a target="_blank" rel="noopener noreferrer" href="https://www.uma.es/secretariageneral/newsecgen/index.php?option=com_content&view=article&id=259:reglamento-de-proteccion-de-datos-de-caracter-personal-de-la-universidad-de-malaga&catid=13&Itemid=124" >{this.props.t('Términos y condiciones')}</a>
                  </div>
                </div>

                <div className='row' style={{ marginTop: '5px', justifyContent: 'center' }}>
                  <div className='col-md-4 col-lg-4 col-sm-12 text-center'>
                    <a href='https://www.uma.es/rce/' target='_blank' rel='noopener noreferrer'><img className='sponsor' src='/assets/img/rce.png' alt='rce'/></a>
                  </div>
                  <div className='col-md-6 col-lg-6 col-sm-12 text-center'>
                    <a href='https://www.uma.es/vicerrectorado-de-proyectos-estrategicos/' target='_blank' rel='noopener noreferrer'><img className='sponsorVice' src='/assets/img/vice.jpg'  alt='vicerrectorado'/></a>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4 col-lg-4 col-sm-12 text-center'>
                    <a href='https://www.uma.es/rce/' target='_blank' rel='noopener noreferrer'><img className='sponsorGeo' src='/assets/img/tec.png' alt='tec'/></a>
                  </div>
                  <div className='col-md-4 col-lg-4 col-sm-12 text-center'>
                    <a href='http://geotecnologias.uma.es/' target='_blank' rel='noopener noreferrer'><img className='sponsorGeo' src='/assets/img/rges.png' alt='rges'/></a>
                  </div>
                  <div className='col-md-4 col-lg-4 col-sm-12 text-center'>
                    <a href='https://www.uma.es/rce/' target='_blank' rel='noopener noreferrer'><img className='sponsorGeo' src='/assets/img/interactividad.png' alt='interactividad'/></a>
                  </div>
                </div>
                <div className='row' style={{ justifyContent: 'center' }}>
                  <div className='col-md-4 col-lg-4 col-sm-12 text-center'>
                    <a href='http://www.andaluciatech.org/' target='_blank' rel='noopener noreferrer'><img className='sponsor' src='/assets/img/andtech.png' alt='andalucia-tech'/></a>
                  </div>
                  <div className='col-md-5 col-lg-5 col-sm-12 text-center'>
                    <a href='https://cartometrics.com/' target='_blank' rel='noopener noreferrer'><img className='cartometrics' src='/assets/img/cartometrics.png' alt='cartometrics'/></a>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4 col-lg-4 col-sm-12 text-center'>
                    <a href='https://www.polodigital.eu/' target='_blank' rel='noopener noreferrer'><img className='img-responsivePolo' src='/assets/img/polo.png' alt='polo'/></a>
                  </div>
                  <div className='col-md-4 col-lg-4 col-sm-12 text-center'>
                    <a href='http://www.malaga.eu/' target='_blank' rel='noopener noreferrer'><img className='img-responsive' src='/assets/img/ayunt.png' alt='ayuntamiento'/></a>
                  </div>
                  <div className='col-md-4 col-lg-4 col-sm-12 text-center'>
                    <a href='https://www.promalaga.es/' target='_blank' rel='noopener noreferrer'><img className='img-responsive' src='/assets/img/promalaga.png' alt='promalaga'/></a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Radium(Help));
