import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'react-notifications/lib/notifications.css';
import { Router } from "@reach/router"
import App from './App';
import { PIC_CONFIG, COVID_CONFIG } from './config'
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<Router>
		<App path="/" config={PIC_CONFIG} />
		<App path="/covid" config={COVID_CONFIG} />
  </Router>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
