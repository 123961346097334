import React from "react";

const withSuspenseFallback = (Component) => {
  return (props) => {
    return <React.Suspense fallback={<div>loading...</div>}>
      <Component {...props} />
    </React.Suspense>;

  }
};

export default withSuspenseFallback;
