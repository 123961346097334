import React, { Component } from 'react';
import firebase from 'firebase/app';
import { NotificationManager } from 'react-notifications';
import Radium from 'radium';
import {withTranslation} from "react-i18next";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      forgot: false,
      terms: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleChange(event) {
    const value = event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value;
    this.setState({ [event.target.id]: value });
  }

  handleLogin(event) {
    if (this.state.forgot) {
      firebase.auth().sendPasswordResetEmail(this.state.email).then(() => {
        this.props.handler(false);
        NotificationManager.info(this.props.t('Te hemos enviado instrucciones de cómo restablecer tu contraseña a la cuenta de correo indicada.'))
      })
    }
    else {
      firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
        .then(() => {
          firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
            .then(response => {
              this.props.handler(false);
              NotificationManager.success(this.props.t('¡Cuenta creada!'))
            })
            .catch(error => {
              NotificationManager.error(`${this.props.t('Ha habido un error al acceder')}. ${this.props.t('Comprueba tu conexión e intenta acceder de nuevo')}`)
            })
        })
        .catch(error => {
          if (error.code === 'auth/email-already-in-use') {
            firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
              .then(response => {
                this.props.handler(false);
                NotificationManager.success(this.props.t('¡Autenticación correcta!'))
              })
              .catch(error => {
                NotificationManager.error(this.props.t('La contraseña no es correcta'))
              })
          }
          else {
            NotificationManager.error(this.props.t('No se ha podido crear la cuenta'));
          }
        });
    }
    event.preventDefault();
  }

  handleLogout(event) {
    firebase.auth().signOut().then(() => {
      this.props.handler(false);
    }).catch((error) => {
      NotificationManager.error(`${this.props.t('Algo salió mal')}...`)
    });
    NotificationManager.success(`${this.props.t('Desconectado.')} ${this.props.t('¡Vuelve pronto!')}`);
    event.preventDefault();
  }

  render() {
    const styles = {
      button: {
        backgroundColor: this.props.styles.button,
        borderColor: this.props.styles.buttonDarker,
        ':hover': {
          backgroundColor: this.props.styles.buttonDarker,
        }
      },
      input: {
        backgroundImage: this.props.styles.buttonUnderline
      }
    };
    const modalHeader = <div className='modal-header'> <h5 className='modal-title'>{this.props.title}</h5><button type='button' className='close' aria-label='Close' onClick={() => this.props.handler(false)}><span aria-hidden='true'>&times;</span></button></div>;

    if (this.props.email) {
      return (
        <form onSubmit={this.handleLogout}>
          <div className='modal fade show' style={{ display: 'block', overflow: 'auto' }} tabIndex='-1'>
            <div className='modal-dialog modal-login' role='document'>
              <div className='modal-content'>
                <div className='card card-signup card-plain'>
                  {modalHeader}
                  <div className='modal-body' style={{ textAlign: 'center' }}>
                    <h4 style={{ fontWeight: 'bold' }}>¡Hola!</h4>
                    <h6>{this.props.t('Estas registrado como ')}{this.props.email}</h6>
                    <input
                      className='btn btn-primary-filters btn-sm'
                      style={styles.button}
                      type='submit'
                      value={this.props.t('Desconectar')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )
    }
    else {
      return (
        <form onSubmit={this.handleLogin}>
          <div className='modal fade show' style={{ display: 'block', overflow: 'auto' }} tabIndex='-1'>
            <div className='modal-dialog modal-login' role='document'>
              <div className='modal-content'>
                <div className='card card-signup card-plain'>
                  {modalHeader}
                  <div className='modal-body'>
                    <div className='backtittle'>
                      <h6 style={{ padding: '5px' }}>
                        {this.props.t('Introduce tu correo y tu contraseña para acceder.')}
                        {' '}
                        {this.props.t('Si no tienes una cuenta')}
                      </h6>
                    </div>
                    <div className='form-row'>
                      <div className='form-group col-md-6'>
                        <label htmlFor='email'>{this.props.t('Email')}</label>
                        <input
                          type='text'
                          className='form-control'
                          style={styles.input}
                          id='email'
                          placeholder='hola@example.com'
                          required
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className='form-group col-md-6'>
                        <label htmlFor='password'>{this.props.t('Contraseña')}</label>
                        <input
                          type='password'
                          className='form-control'
                          style={styles.input}
                          id='password'
                          placeholder={this.props.t('Introduce tu contraseña')}
                          required
                          value={this.state.password}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='form-check'>
                      <label className='form-check-label'>
                        <input className='form-check-input' type='checkbox' id='forgot' checked={this.state.forgot} onChange={this.handleChange} />
                        <span className='form-check-sign'>
                          <span className='check'/>
                        </span>
                        {this.props.t('Si has olvidado tu contraseña, introduce tu e-mail, marca esta casilla y pulsa "Acceder" para recuperarla')}
                      </label>
                    </div>
                    <div className='form-check'>
                      <label className='form-check-label'>
                        <input className='form-check-input' type='checkbox' id='terms' required checked={this.state.terms} onChange={this.handleChange} />
                        <span className='form-check-sign'>
                          <span className='check'/>
                        </span>
                        {this.props.t('He leído y acepto los')}{''}
                        <a target="_blank"
                           rel="noopener noreferrer"
                           href="https://www.uma.es/secretariageneral/newsecgen/index.php?option=com_content&view=article&id=259:reglamento-de-proteccion-de-datos-de-caracter-personal-de-la-universidad-de-malaga&catid=13&Itemid=124">
                          {this.props.t('términos y condiciones')}
                        </a>
                      </label>
                    </div>
                    <p></p>
                    <div className='modal-footer justify-content-center'>
                      <input
                        className='btn btn-primary-filters btn-sm'
                        style={styles.button}
                        type='submit'
                        value={this.props.t('Acceder')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )
    }
  }
}

export default withTranslation()(Radium(Dashboard));
