import React, { Component } from 'react';
import {withTranslation} from "react-i18next";

class FaqsCovid extends Component {
  render() {
    const modalHeader = <div className='modal-header'> <h5 className='modal-title'>{this.props.t(this.props.title)}</h5><button type='button' className='close' aria-label='Close' onClick={() => this.props.handler(false)}><span aria-hidden='true'>&times;</span></button></div>;
    return (
      <div className='modal fade show' style={{ display: 'block', overflow: 'auto' }} tabIndex='-1' role='dialog' aria-labelledby='ModalLabel' aria-hidden='true'>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            {modalHeader}

            <div className='modal-body'>
              <div className='backtittle' style={{ marginTop: '20px' }}>
                <h6 style={{ padding: '5px' }}>
                  1. {this.props.t('¿Cómo registro mi iniciativa?')}
                </h6>
              </div>
              <p className='text-justify'>
                {this.props.t('Pincha en la parte inferior derecha del mapa (en el botón del lápiz), rellena la ficha, dale a enviar y ¡listo!')}
              </p>

              <div className='backtittle'>
                <h6 style={{ padding: '5px', marginTop: '0' }}>
                  2. {this.props.t('¿Qué tipo de iniciativas o ayudas se pueden registrar?')}
                </h6>
              </div>
              <div>
                <ul style={{ paddingLeft: '30px' }}>
                  {this.props.purpose.slice(0, -1).map(purpose =>
                    <li>{purpose.value}</li>
                  )}
                </ul>
                <p className='text-justify'>
                  Pero puedes añadir cualquier otra que estés llevando a cabo con la temática “otros”.
                </p>
              </div>

              <div className='backtittle' style={{ marginTop: '20px' }}>
                <h6 style={{ padding: '5px' }}>
                  3. ¿Cómo me pongo en contacto con las iniciativas?
                </h6>
              </div>
              <p className='text-justify'>
                Si en el mapa pinchas en un punto te aparece la información y el lugar o contacto donde poder localizar la ayuda o servicio.
              </p>

              <div className='backtittle' style={{ marginTop: '20px' }}>
                <h6 style={{ padding: '5px' }}>
                  4. ¿Qué tipos de colectivos pueden registrar su iniciativa?
                </h6>
              </div>
              <p className='text-justify'>
                Con colectivo nos referimos a toda aquella asociación, administración pública, empresa o agrupación de personas, que esté llevando a cabo algún tipo de iniciativa o servicio que sirva de ayuda o servicio a los ciudadanos malagueños.
              </p>

              <div className='backtittle' style={{ marginTop: '20px' }}>
                <h6 style={{ padding: '5px' }}>
                  5. ¿Qué pasa si no soy un colectivo (soy una persona individual)?
                </h6>
              </div>
              <p className='text-justify'>
                Si eres una persona que está realizando o te prestas a realizar algún tipo acción solidaria (por ejemplo: haces mascarillas o te prestas para hacer la compra a personas mayores) añade tu iniciativa a la plataforma para que otras personas puedan contactarte y solicitar tu ayuda.
              </p>

              <div className='backtittle' style={{ marginTop: '20px' }}>
                <h6 style={{ padding: '5px' }}>
                  6. ¿A qué nos referimos con <i>Nombre de la iniciativa - Organizador</i> ?
                </h6>
              </div>
              <p className='text-justify'>
                Cuando introduces tu iniciativa debes indicar un nombre y seguidamente, si eres una organización o el organizador que realiza esa iniciativa, indicarlo también (ejemplo: donación de alimentos + asociación (nombre)).
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(FaqsCovid);
