import React, { Component } from 'react';
import Radium from 'radium';
import {withTranslation} from "react-i18next";

class FilterPanel extends Component {
    constructor(props) {
        super(props);
        if(localStorage.getItem('checks')){
            this.state = JSON.parse(localStorage.getItem('checks'));
        }
        else {
            this.state = {
                filters: [],
                f_tematica: Array(props.options.length).fill(false)
            };
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmission = this.handleSubmission.bind(this);
    }

    componentWillUnmount(){
        localStorage.setItem('checks',JSON.stringify(this.state));
    }
    async restoreFilters() {
        await this.setState({f_tematica: Array(this.props.options.length).fill(false)})
        this.props.handler(false);
        this.props.removeFilters(this.state.f_tematica, false);
    }
    handleChange(event) {
        const position = parseInt(event.target.id);
        const updatedCheckedState = this.state.f_tematica.map((item, index) =>
            index === position ? !item : item
        );

        this.setState({f_tematica: updatedCheckedState});
    }
    handleSubmission(event) {
        this.props.handleFilters({[this.props.id]: this.state.f_tematica});
        this.props.handler(false);
        event.preventDefault();
    }
    render() {
        const styles = {
          button: {
            backgroundColor: this.props.styles.button,
            borderColor: this.props.styles.buttonDarker,
            ':hover': {
              backgroundColor: this.props.styles.buttonDarker,
            }
          }
        };
        const header = <div className='modal-header'><h5 className='modal-title'>{this.props.title}</h5><button type='button' className='close' aria-label='Close' onClick={() => this.props.handler(false)}><span aria-hidden='true'>&times;</span></button></div>;
        const options = this.props.options.map((option, index) => {
            return (
                <div key={index} className='form-check'>
                    <label className='form-check-label'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            id={index}
                            name={option}
                            value={option}
                            onChange={this.handleChange}
                            checked={this.state.f_tematica[index]}
                        /> {option}
                        <span className='form-check-sign'>
                            <span className='check'></span>
                        </span>
                    </label>
                </div>
            )
        });

        return (
            <form onSubmit={this.handleSubmission}>
                <div className='modal fade show' style={{ display: 'block', overflow: 'auto' }} tabIndex='-1' role='dialog' aria-labelledby='ModalLabel' aria-hidden='true'>
                    <div className='modal-dialog' role='document'>
                        <div className='modal-content'>
                            {header}
                            <div className='modal-body'>
                                <div className='backtittle'>
                                    <h6 style={{padding:'5px' }}>{this.props.description}</h6></div>
                                {options}
                            </div>

                            <div className='modal-footer justify-content-center'>
                                <input
                                    type='submit'
                                    key='submit'
                                    className='btn btn-primary-filters btn-sm'
                                    style={styles.button}
                                    value={this.props.t('Filtrar')}
                                />
                                <button
                                    type='button'
                                    key='button'
                                    className='btn btn-primary-filters btn-sm'
                                    style={styles.button}
                                    onClick={() => this.restoreFilters()}
                                >
                                    {this.props.t('Eliminar TODOS los filtros')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );

    }
}
export default withTranslation()(Radium(FilterPanel));
