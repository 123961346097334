import React, { Component } from 'react';
import firebase from 'firebase/app';
import { NotificationManager } from 'react-notifications';
import Radium from 'radium';
import ODSTags from "./ODSTags";
import {withTranslation} from "react-i18next";

const style = {
  sidebarHeader: {
    display: 'grid',
    gridTemplateColumns: 'auto auto'
  },
  card: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    overflow: 'auto'
  },
  image: {
    // heigth: '100%',
    // padding: 0,
    // margin: 0
  }
}

class Sidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      display: 'block'
    }
    this.deleteFeature = this.deleteFeature.bind(this)
    this.miniSidebar = this.miniSidebar.bind(this)
  }

  miniSidebar() {
    if (this.state.display === 'none') {
      this.setState({ display: 'block' })
    }
    else {
      this.setState({ display: 'none' })
    }
  }

  deleteFeature(id, coordinates) {
    firebase.firestore().collection(this.props.config.collection).doc(String(id)).delete()
      .then(response => {
        this.props.handler(false, `${this.props.t('Iniciativa eliminada con éxito')}. ${this.props.t('¡Sentimos que te vayas')}! :( `);
      }).catch(error => {
        NotificationManager.error(this.props.t('Ha ocurrido un error al eliminar la iniciativa.'));

      });
  };

  editFeature() {
    this.props.handler({ type: 'edit', title: this.props.t('Añade una iniciativa'), data: { properties: this.props.featureData, geometry: { coordinates: this.props.featureData.featureLocation } } });
  }

  render() {
    const styles = {
      editButton: {
        width: "90px",
        backgroundColor: this.props.styles.button,
        borderColor: this.props.styles.buttonDarker,
        ':hover': {
          backgroundColor: this.props.styles.buttonDarker,
        }
      },
      deleteButton: {
        width: "90px",
        backgroundColor: '#d64a4a',
        borderColor: '#bb2f2f',
        ':hover': {
          backgroundColor: '#bb2f2f',
        }
      }
    };

    if (this.props.show) {
      let tagged,
        area = JSON.parse(this.props.featureData.featureProperties.area),
        purpose = JSON.parse(this.props.featureData.featureProperties.purpose);

      if (area.length > 0 || purpose.length > 0) {
        if (area.length > 0) {
          area = `${this.props.t('Ámbitos')}: #` + area.map(el => this.props.t(el).replace(/\s/g, '')).join(' #')
        }
        else {
          area = ''
        }
        if (purpose.length > 0) {
          purpose = `${this.props.t('Temáticas')}: #` + purpose.map(el => this.props.t(el).replace(/\s/g, '')).join(' #')
        }
        else {
          purpose = ''
        }
        tagged = <span>{this.props.t('Esta iniciativa contiene los tags')}:<br/>{area}<br/>{purpose} </span>
      }
      else {
        tagged = <a href="mailto:info@teciudadania.uma.es">
          {this.props.t('Esta iniciativa no contiene ningún tag. ¡Contacta con nosotros y ayúdanos a mejorarla!')}
        </a>
      }

      const url = (this.props.featureData.featureProperties.url === null || this.props.featureData.featureProperties.url === '') ? null : <div><a href={this.props.featureData.featureProperties.url} target='_blank' rel="noopener noreferrer" className='card-link'><i className='fa fa-link' style={{ marginLeft: '8px' }}></i>Website</a></div>,
        twitter = (this.props.featureData.featureProperties.twitter === null || this.props.featureData.featureProperties.twitter === '') ? null : <div><a href={this.props.featureData.featureProperties.twitter} target='_blank' rel="noopener noreferrer" className='card-link'><i className='fa fa-twitter' style={{ marginLeft: '8px' }}></i> Twitter</a></div>,
        youtube = (this.props.featureData.featureProperties.youtube === null || this.props.featureData.featureProperties.youtube === '') ? null : <div><a href={this.props.featureData.featureProperties.youtube} target='_blank' rel="noopener noreferrer" className='card-link'><i className='fa fa-youtube' style={{ marginLeft: '8px' }}></i> Youtube</a></div>,
        email = (this.props.featureData.featureProperties.mail === null || this.props.featureData.featureProperties.mail === '') ? null : <div><a href={'mailto:' + this.props.featureData.featureProperties.mail} target='_blank' rel="noopener noreferrer" className='card-link'><i className='fa fa-envelope' style={{ marginLeft: '8px' }}></i> {this.props.featureData.featureProperties.mail} </a></div>,
        phone = (this.props.featureData.featureProperties.phone === null || this.props.featureData.featureProperties.phone === '') ? null : <div><a href={'tel:' + this.props.featureData.featureProperties.phone} target='_blank' rel="noopener noreferrer" className='card-link'><i className='fa fa-phone' style={{ marginLeft: '8px' }}></i> {this.props.featureData.featureProperties.phone}</a></div>,
        facebook = (this.props.featureData.featureProperties.facebook === null || this.props.featureData.featureProperties.facebook === '') ? null : <div><a href={this.props.featureData.featureProperties.facebook} target='_blank' rel="noopener noreferrer" className='card-link'><i className='fa fa-facebook-square' style={{ marginLeft: '8px' }}></i> Facebook</a></div>,
        deletePoint = (this.props.featureData.featureProperties.creator === this.props.userEmail || this.props.userEmail === 'info.teciudadania@uma.es') ? <button className='btn btn-primary-filters btn-sm' key='deleteButton' style={styles.deleteButton} onClick={() => this.deleteFeature(this.props.featureData.featureProperties.id, this.props.featureData.featureLocation)} >{this.props.t('Eliminar')} </button> : null,
        editPoint = (this.props.featureData.featureProperties.creator === this.props.userEmail || this.props.userEmail === 'info.teciudadania@uma.es') ? <button className='btn btn-primary-filters btn-sm' key='editButton' style={styles.editButton} onClick={() => this.editFeature()} >{this.props.t('Editar')} </button> : null,
        img = !this.props.featureData.featureProperties.image ? null : <img style={{ maxWidth: '100%', padding: 0, margin: 0 }} src={this.props.featureData.featureProperties.image} alt='initiative' />,
        tags = tagged;

      return (
        <div className='card card-sidebar' style={style.card}>
          <div className='modal-header' style={style.sidebarHeader}>
            <h5 className='modal-title'>{this.props.featureData.featureProperties.name}</h5>
            <div>
              <button type='button' className='close' aria-label='Close' onClick={this.props.closeSidebar}>
                <span aria-hidden='true'>&times;</span>
              </button>
              <button type='button' className='close' aria-label='Close' onClick={this.miniSidebar}>
                <span aria-hidden='true'>-</span>
              </button>
            </div>
          </div>
          <div style={{ display: this.state.display }}>
            <div className="card-img-top" style={style.image}>
              {img}
            </div>
            <div className='card-body'>

              <h6 className="text-muted">{this.props.featureData.featureProperties.address}</h6>

              <p className='blockquote undefined text-justify' dangerouslySetInnerHTML={{ __html: this.props.featureData.featureProperties.description }}></p>
              {this.props.featureData.featureProperties.demands ?
                (<>
                  <div>{this.props.t('Demandas')}:</div>
                  <p className='blockquote undefined text-justify'
                     dangerouslySetInnerHTML={{__html: this.props.featureData.featureProperties.demands}}/>
                </>)
                : null
              }
              <div className='backtittleTwo'>
                <div className='backtittleThree'>
                  <h6 className="text-muted" style={{ padding: '5px' }}>{this.props.t('Contacto')}:</h6></div>

                {url}
                {twitter}
                {youtube}
                {facebook}
                {email}
                {phone}

                <h6 className="text-muted" style={{ marginLeft: '8px' }}>{this.props.t('Compartir iniciativa en')}:</h6>
                <a target='_blank' rel="noopener noreferrer" href={"https://twitter.com/home?status=" + encodeURIComponent(window.location.hostname + '/#15/' + this.props.featureData.featureLocation[1] + '/' + this.props.featureData.featureLocation[0])} className='card-link'><i className='fa fa-twitter' style={{ marginLeft: '8px' }}></i> </a>
                <a target='_blank' rel="noopener noreferrer" href={"https://facebook.com/sharer/sharer.php?u=" + encodeURIComponent(window.location.hostname + '/#15/' + this.props.featureData.featureLocation[1] + '/' + this.props.featureData.featureLocation[0])} className='card-link'><i className='fa fa-facebook-square'> </i></a>

                <div style={{ marginLeft: '8px', marginRight: '8px' }}>
                  <span>{tags}</span>
                </div>
                {this.props.featureData.featureProperties.ods
                  ? (<ODSTags ods={this.props.featureData.featureProperties.ods} />)
                  : null}
                <div className='modal-body' style={{ textAlign: 'center' }}>
                  {deletePoint}
                  {editPoint}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return null;
    }
  }
}

export default withTranslation()(Radium(Sidebar));
