import React, { Component } from 'react';
import ReactGA from 'react-ga';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import * as turf from '@turf/turf';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import Header from './Header';
import Sidebar from './Sidebar';
import Modal from './Modal';
import district from './districts.json';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import throttle from 'lodash/throttle';
import { getFeaturesByClusterId } from "./utils";
import './i18n';
import withSuspenseFallback from "./hocs/withSuspenseFallback";
import withCustomTranslation from "./hocs/withCustomTranslation";

require('dotenv').config();

ReactGA.initialize('UA-149945198-1');
ReactGA.pageview(window.location.pathname + window.location.search);

mapboxgl.accessToken = 'pk.eyJ1IjoiY2FydG9tZXRyaWNzIiwiYSI6ImNqOGJ2ZXIzazAxd3kyd3AyMDVrOGpzNWkifQ.KwvwFfoDOeLnjR1gEHO8tg';

firebase.initializeApp({
    apiKey: 'AIzaSyAPcJPwwsRESS3m5NNvA5PaXTNRkSo3_AM',
    authDomain: 'catedras-uma.firebaseapp.com',
    databaseURL: 'https://catedras-uma.firebaseio.com',
    projectId: 'catedras-uma',
    storageBucket: 'catedras-uma.appspot.com',
    messagingSenderId: '657639469404'
});

export const storage = firebase.storage();

class App extends Component {

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this)
        this.handleFilters = this.handleFilters.bind(this)
        this.applyFilters = this.applyFilters.bind(this)
        this.removeFilters = this.removeFilters.bind(this);
        localStorage.removeItem('checks');
        this.closeSidebar = this.closeSidebar.bind(this);
        this.printData = this.printData.bind(this);
        this.gotoselected = this.gotoselected.bind(this);

        // objects for caching and keeping track of HTML marker objects (for performance)
        this.markersCache = {};
        this.markersOnScreen = {};

        this.state = {
            modal: props.config.helpModal,
            filters: ['Accesibilidad', 'Arte urbano', 'Arquitectura', 'Autogestión', 'Cuidado', 'Culto', 'Cultura', 'Deporte', 'Derechos sociales', 'Diversidad', 'Educación', 'Gentrificación / Turistificación', 'Integración', 'Igualdad', 'Mediación', 'Medio ambiente', 'Movilidad sostenible', 'Participación ciudadana', 'Patrimonio material', 'Patrimonio cultural inmaterial', 'Personas mayores', 'Política social', 'Urbanismo', 'Salud'],
            stepsEnabled: false,
            data: {
                "type": "FeatureCollection",
                "features": []
            },
            tempData: {
                "type": "FeatureCollection",
                "features": []
            },
            site: {
                // title: 'Iniciativas Ciudadanas',
                collection: this.props.config.collection,
                buttons: [
                    {
                        name: this.props.t('Temática'),
                        description: this.props.t('Visualiza en el mapa el tipo de iniciativa por temática que ha sido llevada a cabo por l@s ciudadan@s.'),
                        id: 'purpose',
                        filters: props.config.filters
                    }, 
                    // {
                    //     name: this.props.t('Zonas'),
                    //     description: this.props.t('Si quieres enterarte de las iniciativas que han surgido en tu distrito o en cualquier otro, haz uso de este filtro y las verás en el mapa.'),
                    //     id: 'district',
                    //     filters: district.features.map((feature) => feature.properties.name)
                    // }
                ]
            },
            user: {
                email: localStorage.getItem('email'),
                uid: null
            },
            satelliteImage: false,
            featureData: {
                show: false
            },
            map: {
                filter: {}
            },
            visited: true,
            checkingAuth: true
        }
    }

    gotoselected(name) {
        for (var i = 0; i < this.state.data.features.length; i++) {
            if (this.state.data.features[i].properties.name === name) {
                this.map.setCenter(this.state.data.features[i].geometry.coordinates);
                this.map.setZoom(20);
            }
        }
    }

    closeSidebar() {
        this.setState({ featureData: { show: false } })
    }

    toggleModal(options, notification, id, help) {
        this.setState({ modal: options, featureData: { show: false } })
        if (notification) {
            NotificationManager.info(notification)
            if (id !== undefined) {
                this.draw.delete(id)
            }
            firebase.firestore().collection(this.state.site.collection).get().then(querySnapshot => {
                let template = {
                    "type": "FeatureCollection",
                    "features": []
                }
                querySnapshot.forEach(doc => {
                    template.features.push(doc.data())
                });

                this.setState({ data: template })
                this.map.getSource('userActivitiesSource').setData(this.state.data)

                if (this.map.getSource('userSelected') !== undefined) {
                    this.selectFeature(null);
                }

            })
        }

        if (!this.state.visited) {
            this.setState(() => ({ stepsEnabled: true }));
        }
    }

    printData(selectedLayers) {
        return this.map.queryRenderedFeatures({ layers: selectedLayers });
    }

    handleFilters(conditions) {
        if (this.map.getSource('userSelected') !== undefined) {
            this.selectFeature(null);
        }

        const filters = this.state.map.filter;
        filters[Object.keys(conditions)[0]] = Object.values(conditions)[0];
        if (filters.purpose !== undefined) {
            filters.purpose = filters.purpose.filter(purpose => district.features.map((feature) => feature.properties.name).includes(purpose) === false)
        }
        this.setState({ map: { filter: filters } });
    }

    removeFilters(filterstoremove, t) {
        if (this.state.map.filter.purpose !== undefined) {
            if (t === true) {
                delete this.state.map.filter['purpose'];
            } else {
                var purpose = filterstoremove;
                if (filterstoremove.length > 0)
                    this.setState({ map: { filter: { purpose } } });
                else
                    this.setState({ map: { filter: {} } });
            }
        } else if (t === false) {
            this.setState({ map: { filter: {} } });
        }
    }

    onExit = () => {
        this.setState(() => ({ stepsEnabled: false, visited: true }));
    };

    applyFilters(filterObject) {
        let empty = {
            "type": "FeatureCollection",
            "features": []
        }
        const filteredData = {
            "type": "FeatureCollection",
            "features": [...this.state.data.features]
        };

        if (filterObject['purpose']) {
            let selectedPurpose = []
            for (let index = 0; index < this.state.filters.length; index++) {
                const item = this.state.filters[index];
                if (filterObject['purpose'][index]) selectedPurpose.push(item)
                
            }
            if (selectedPurpose.length > 0) {
                filteredData.features = this.state.data.features.filter(({ properties: { purpose } }) => {
                    return selectedPurpose.some(entry => purpose.includes(entry));
                })
            }
        }

        let selectedDistrict = Object.entries(filterObject).filter((entry) => entry[0] === 'district')
        selectedDistrict = selectedDistrict[0] === undefined ? [] : (selectedDistrict[0][1].length > 0 ? selectedDistrict : [])
        if (selectedDistrict.length > 0) {
            let template = {
                "type": "FeatureCollection",
                "features": []
            }
            let newDistricts = district.features.filter(district => selectedDistrict[0][1].includes(district.properties.name))
            template.features = newDistricts;
            let pointsWithin = turf.pointsWithinPolygon(filteredData, template);
            this.map.getSource('districtPolygons').setData(template);
            this.map.getSource('userActivitiesSource').setData(pointsWithin);
        } else {
            if (this.map.getSource('userActivitiesSource') !== undefined) {
                this.map.getSource('userActivitiesSource').setData(filteredData)
                this.map.getSource('districtPolygons').setData(empty)
            }
        }
    }

    // _toggle(satelliteImage) {
    //   if (satelliteImage) {
    //     this.map.setStyle('mapbox://styles/mapbox/satellite-v9');
    //   }
    //     this.map.setStyle('mapbox://styles/mapbox/light-v9');
    //   else {
    //   }
    // }

    checkFirstVisit() {
        const visited = localStorage.getItem('visited');
        if (!visited) {
            localStorage.setItem('visited', true);
            this.setState({ visited: false });
        }
    }

    setUpAuthObserver() {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.setState({
                    user: {
                        email: user.email,
                        uid: user.uid
                    },
                    checkingAuth: false
                });
                localStorage.setItem('email', user.email);
            } else {
                this.setState({
                    user: {
                        email: null,
                        uid: null
                    },
                    checkingAuth: false
                });
                localStorage.setItem('email', null);
            }
        });
    }

    selectedFeature = {
        feature: null,
        relatedFeatureIds: [],
    };

    setSelectedFeature(feature) {
        if (!feature || !feature.properties || feature.properties.cluster) {
            // empty state
            this.selectedFeature = { feature: null, relatedFeatureIds: [] };
            return;
        }

        if (this.selectedFeature && this.selectedFeature.feature && this.selectedFeature.feature.properties.id === feature.properties.id) {
            // selected feature hasn't changed
            return;
        }

        if (feature.properties.related == null) {
            this.selectedFeature = { feature, relatedFeatureIds: [] };
            return;
        }

        const relatedPoints = feature.properties.related.slice(1, -1).split(',')
            .map(str => parseInt(str))
            .filter(id => id && !isNaN(id));

        this.selectedFeature = { feature, relatedFeatureIds: relatedPoints };
    };

    selectFeature = e => {
        if (!e || !e.features || !e.features[0] || !e.features[0].properties) {
            // clean the map and variable
            this.removeFeatureSelectionLayersAndSources();
            this.setSelectedFeature(null);
            return;
        }

        if (e.features[0].properties.cluster) return;

        this.setSelectedFeature(e.features[0]);

        this.renderSelectedFeature();
    };

    addOrUpdateSource = (id, options) => {
        const source = this.map.getSource(id);
        if (source) {
            source.setData(options.data);
        } else {
            this.map.addSource(id, options);
        }
    };

    addLayerIfDoesNotExist = (id, layerObject, before, options) => {
        if (!this.map.getLayer(id)) this.map.addLayer({ ...layerObject, id }, before, options);
    };

    renderSelectedFeature = async () => {
        const { feature, relatedFeatureIds } = this.selectedFeature;
        const { styles } = this.props.config;

        let lines = {
            "type": "FeatureCollection",
            "features": []
        };

        let unclusteredRelatedFeatures = [];

        let showSelectedFeature = true;

        if (feature && relatedFeatureIds.length) {
            const allRelatedFeatures = this.state.data.features
              .filter(feature => relatedFeatureIds.includes(feature.properties.id));

            const allClusters = this.map.querySourceFeatures('userActivitiesSource', { filter: ['==', 'cluster', true] });
            const allClustersById = allClusters.reduce((acc, cluster) => ({...acc, [cluster.id]: cluster }), {});

            const featuresByClusterId = await getFeaturesByClusterId(allClusters, this.map.getSource('userActivitiesSource'));

            const clusterIdsByChildrenId = Object.entries(featuresByClusterId).reduce((acc, [clusterId, featureIds]) => {
              featureIds.forEach((id) => acc[id] = clusterId);
              return acc;
            }, {});

            const relatedClusters = [];
            for (const relatedFeature of allRelatedFeatures) {
                const currClusterId = clusterIdsByChildrenId[relatedFeature.properties.id];
                if (currClusterId) {
                    relatedClusters.push(allClustersById[currClusterId]);
                } else {
                    unclusteredRelatedFeatures.push(relatedFeature);
                }
            }

            showSelectedFeature = !!(clusterIdsByChildrenId[feature.properties.id]);
            
            const selecteCoordinates = showSelectedFeature ? allClustersById[clusterIdsByChildrenId[feature.properties.id]].geometry.coordinates : feature.geometry.coordinates;

            lines = {
                "type": "FeatureCollection",
                "features": [...unclusteredRelatedFeatures, ...relatedClusters].map(entry => {
                    return {
                        "type": "Feature",
                        "properties": entry.properties,
                        "geometry": {
                            "type": "LineString",
                            "coordinates": [selecteCoordinates, entry.geometry.coordinates]
                        }
                    }
                })
            }
        }

        this.addOrUpdateSource('userSelected', {
            'type': 'geojson',
            'data': lines,
            lineMetrics: true,
        });

        this.addOrUpdateSource('selectedFeature', {
            type: 'geojson',
            data: turf.featureCollection([...unclusteredRelatedFeatures, showSelectedFeature ? [] : feature])
        });

        this.addLayerIfDoesNotExist('userSelected', {
            source: 'userSelected',
            type: 'line',
            'layout': {
                'line-join': 'round',
                'line-cap': 'round'
            },
            'paint': {
                'line-width': 2,
                'line-gradient': styles.lineGradient
            }
        });

        this.addLayerIfDoesNotExist('selectedFeature', {
            source: 'selectedFeature',
            type: 'circle',
            paint: {
                'circle-radius': [
                    "interpolate", ["linear"], ["zoom"],
                    5, 1,
                    12, 4
                ],
                'circle-stroke-width': [
                    "interpolate", ["linear"], ["zoom"],
                    5, 3,
                    12, 8
                ],
                'circle-color': styles.circle,
                'circle-stroke-color': styles.circle

            }
        });
    };

    removeFeatureSelectionLayersAndSources = () => {
        if (this.map.getSource('userSelected') !== undefined) {
            this.map.removeLayer('userSelected');
            this.map.removeLayer('selectedFeature');
            this.map.removeSource('userSelected');
            this.map.removeSource('selectedFeature');
        }
    }

    getClusterClickHandler = (feature) => (e) => {
        const clusterId = feature.properties.cluster_id;

        this.map.getSource('userActivitiesSource').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
              if (err || !feature?.geometry) return;

              this.map.easeTo({
                  center: feature.geometry.coordinates,
                  zoom: zoom
              });
          }
        );
    }

    createMarker = (feature = {}) => {
        const { point_count } = feature.properties || {};
        const el = document.createElement('div');
        el.classList.add('marker');
        el.textContent = point_count;

        const countNum = Number(point_count);
        const size = countNum >= 100 ? 44 : countNum >= 50 ? 42 : countNum >= 10 ? 40 : 38;

        el.style.width = el.style.height = `${size}px`;

        el.addEventListener('pointerdown', this.getClusterClickHandler(feature));

        return el;
    }


    updateMarkers = () => {
        const newMarkers = {};
        const features = this.map.querySourceFeatures('userActivitiesSource');

        // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
        // and add it to the map if it's not there already
        for (let i = 0; i < features.length; i++) {
            if (!features[i]?.geometry) continue;

            const coords = features[i].geometry.coordinates;
            const props = features[i].properties;
            if (!props.cluster || !props.point_count) continue;
            const clusterId = props.cluster_id;

            let marker = this.markersCache[clusterId];
            if (!marker) {
                const el = this.createMarker(features[i]);
                marker = this.markersCache[clusterId] = new mapboxgl.Marker({
                    element: el
                }).setLngLat(coords);
            }
            newMarkers[clusterId] = marker;

            if (!this.markersOnScreen[clusterId]) marker.addTo(this.map);
        }
        // for every marker we've added previously, remove those that are no longer visible
        for (const id in this.markersOnScreen) {
            if (!newMarkers[id]) this.markersOnScreen[id].remove();
        }
        this.markersOnScreen = newMarkers;
    }

    componentDidUpdate() {
        if (!this.map.getLayer('userActivities')) return;

        this.applyFilters(this.state.map.filter);
    }

    componentDidMount() {
        const { styles, favicon } = this.props.config;

        document.getElementById('favicon').href = favicon;

        this.setUpAuthObserver();
        this.checkFirstVisit();

        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/mapbox/light-v9',
            center: [-3.932, 40.619],
            zoom: 5.2,
            hash: true,
            attributionControl: false
        });

        this.map.addControl(new mapboxgl.AttributionControl({ customAttribution: ['Developed by <a href="https://cartometrics.com" target="_blank"><strong>Cartometrics</strong></a>'] }), 'bottom-right');

        this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

        this.draw = new MapboxDraw({
            controls: {
                combine_features: false,
                uncombine_features: false,
                trash: false,
                line_string: false,
                polygon: false
            }
        })

        this.map.addControl(this.draw, 'bottom-right');

        document.getElementsByClassName('mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_point')[0].title = this.props.t('Añadir iniciativa');
        document.getElementsByClassName('mapboxgl-ctrl-zoom-in')[0].title = this.props.t('Zoom más');
        document.getElementsByClassName('mapboxgl-ctrl-zoom-out')[0].title = this.props.t('Zoom menos');
        document.getElementsByClassName('mapboxgl-ctrl-compass')[0].title = this.props.t('Orientar a norte');

        this.map.on('load', () => {

            let layers = this.map.getStyle().layers;
            let labelLayerId;
            for (let i = 0; i < layers.length; i++) {
                if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
                    labelLayerId = layers[i].id;
                    break;
                }
            }

            this.map.addLayer({
                'id': '3d-buildings',
                'source': 'composite',
                'source-layer': 'building',
                'filter': ['==', 'extrude', 'true'],
                'type': 'fill-extrusion',
                'minzoom': 15,
                'paint': {
                    'fill-extrusion-color': '#aaa',
                    'fill-extrusion-height': [
                        'interpolate', ['linear'], ['zoom'],
                        15, 0,
                        15.05, ['get', 'height']
                    ],
                    'fill-extrusion-base': [
                        'interpolate', ['linear'], ['zoom'],
                        15, 0,
                        15.05, ['get', 'min_height']
                    ],
                    'fill-extrusion-opacity': .6
                }
            }, labelLayerId);

            this.map.addSource('districtPolygons', {
                type: 'geojson',
                data: district
            });
            let empty = {
                "type": "FeatureCollection",
                "features": []
            }
            this.map.getSource('districtPolygons').setData(empty)


            firebase.firestore().collection(this.state.site.collection).get().then(querySnapshot => {
                let template = {
                    "type": "FeatureCollection",
                    "features": []
                }
                querySnapshot.forEach(doc => {
                    template.features.push(doc.data())
                });

                this.setState({ data: template })

                this.map.addLayer({
                    id: 'district',
                    source: 'districtPolygons',
                    type: 'fill',
                    'paint': {
                        'fill-color': styles.navbar,
                        'fill-opacity': 0.2
                    }
                });

                this.map.addSource('userActivitiesSource', {
                    type: 'geojson',
                    data: this.state.data,
                    cluster: true,
                    clusterMaxZoom: 30,
                    clusterRadius: 40,
                })

                this.map.addLayer({
                    id: 'userActivities',
                    source: 'userActivitiesSource',
                    filter: ['!', ['has', 'point_count']],
                    type: 'circle',
                    paint: {
                        'circle-radius': [
                            "interpolate", ["linear"], ["zoom"],
                            // zoom is 5 (or less) -> circle radius will be 1px
                            5, ['match',
                                ['get', 'nexus'],
                                'true', 5,
                                3
                            ],
                            // zoom is 10 (or greater) -> circle radius will be 5px
                            12, ['match',
                                ['get', 'nexus'],
                                'true', 12,
                                10
                            ]
                        ],

                        'circle-color': styles.circle,
                        'circle-opacity': 0.8
                    },
                });
            });

          ['userActivities', 'selectedFeature'].forEach(activityType => {
                this.map.on('mouseenter', activityType, () => {
                    this.map.getCanvas().style.cursor = 'pointer';
                });

                this.map.on('mouseleave', activityType, () => {
                    this.map.getCanvas().style.cursor = '';
                });

                this.map.on('click', activityType, e => {
                    let featureProperties = e.features[0].properties,
                        featureLocation = e.features[0].geometry.coordinates;

                    if (featureProperties.cluster) return;

                    this.setState({ featureData: { featureProperties, featureLocation, show: true } })
                });

                this.map.on('touchend', activityType, e => {
                    let featureProperties = e.features[0].properties,
                        featureLocation = e.features[0].geometry.coordinates
                    this.setState({ featureData: { featureProperties, featureLocation, show: true } })
                });
            });

            this.map.on('click', 'userActivities', this.selectFeature);
            this.map.on('click', 'selectedFeature', this.selectFeature);
            this.map.on('touchend', 'userActivities', this.selectFeature);

            this.map.on('draw.create', e => {
                let newPoint = e.features[0];
                this.toggleModal({ type: 'edit', isUpdate: true, title: this.props.t('Añade una iniciativa'), data: newPoint });
            });

            this.map.on('zoomend', () => {
                const { feature, relatedFeatureIds } = this.selectedFeature;
                if (feature && relatedFeatureIds.length) {
                    this.renderSelectedFeature();
                }
            });

            this.map.on('render', throttle(() => {
                if (!this.map.getSource('userActivitiesSource') || !this.map.isSourceLoaded('userActivitiesSource')) return;
                this.updateMarkers();
            }, 100));
        });
    }

    componentWillUnmount() {
        this.map.remove();
    }

    render() {
        const style = {
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '100%'
        };
        const initialStep = 0,
            steps = [
                {
                    element: '.navbar-color-on-scroll',
                    intro: `${this.props.config.welcome} ${this.props.t('En esta barra encontrarás los filtros necesarios para buscar las iniciativas creadas en la ciudad')}.`,
                },
                {
                    element: '.mapbox-gl-draw_point',
                    intro: this.props.t('Utiliza el lápiz para añadir la iniciativa al mapa. Antes deberás estar registrado en la plataforma'),
                },

            ];

        return (
            <div style={style} ref={el => this.mapContainer = el}>
                <Header
                    path={this.props.path}
                    config={this.props.config}
                    nameList={this.state.data.features.map((feature) => feature.properties.name)}
                    buttons={this.state.site.buttons}
                    handler={this.toggleModal}
                    email={this.state.user.email}
                    printData={this.printData}
                    gotoselected={this.gotoselected}
                    mapData={[["Name", "Description", "Website", "Lat", "Long"]]}
                    checkingAuth={this.state.checkingAuth}
                    close={this.closeSidebar}
                />
                <Modal
                    config={this.props.config}
                    type={this.state.modal.type}
                    removeFilters={this.removeFilters}
                    title={this.state.modal.title}
                    id={this.state.modal.id}
                    subtitle={this.state.modal.subtitle}
                    description={this.state.modal.description}
                    image={this.state.modal.image}
                    textBeforeIcons={this.state.modal.textBeforeIcons}
                    email={this.state.user.email}
                    handler={this.toggleModal}
                    handleFilters={this.handleFilters}
                    options={this.state.modal.options}
                    data={this.state.modal.data}
                    collection={this.state.site.collection}
                    points={this.state.data.features}
                />
                <Sidebar
                    config={this.props.config}
                    styles={this.props.config.styles}
                    handler={this.toggleModal}
                    featureData={this.state.featureData}
                    collection={this.state.site.collection}
                    userEmail={this.state.user.email}
                    show={this.state.featureData.show}
                    closeSidebar={this.closeSidebar}
                />
                <NotificationContainer />
                <Steps
                    enabled={this.state.stepsEnabled}
                    steps={steps}
                    initialStep={initialStep}
                    onExit={this.onExit}
                    options={{
                        nextLabel: this.props.t('Siguiente'),
                        prevLabel: this.props.t('Anterior'),
                        skipLabel: this.props.t('Saltar'),
                        doneLabel: this.props.t('Hecho')
                    }}
                />
            </div>
        );
    }
}

export default withSuspenseFallback(withCustomTranslation(App));
